import React from 'react';
import PropTypes from 'prop-types';

import RegisterCta from '../utility/registerCta';
import HandDrawnButton from './handDrawnButton';
import FeatureFlagsContext from '../../src/featureFlagsContext';

// This isn't the correct spot for this config but as this is the only place it
// is used, it's fine for now.
const DestinationLookup = {
  development: 'http://localhost:3000',
  staging:  'https://app.staging.blinkcats.co.uk',
  production: 'app.blinkcats.co.uk', // Waiting for Damian to confirm this subdomain.
};

class Cta extends React.Component {
  static contextType = FeatureFlagsContext

  render() {
    const { ctaButtonText, large, id, handleClick } = this.props;
    let ctaUrl = '/catRegistration';

    // If the twin frontends are enabled, use the new site URL.
    const featureFlags = this.context;
    if (featureFlags && featureFlags.twin_frontends_enabled) {
      // ROLLBAR_ENV can be 'production', 'development', or 'staging'.
      ctaUrl = DestinationLookup[ROLLBAR_ENV];
    }

    return (
      <RegisterCta>
        <HandDrawnButton
          path={ ctaUrl }
          text={ ctaButtonText }
          large={ large }
          id={ id }
          handleClick={ handleClick }
        />
      </RegisterCta>
    );
  }
}

Cta.propTypes = {
  ctaButtonText: PropTypes.string,
  large: PropTypes.bool,
  id: PropTypes.string,
  handleClick: PropTypes.func,
};

export default Cta;
